export default [
	{
		title: 'Dashboard',
		to: { name: 'index' },
		icon: { icon: 'tabler-smart-home' },
	},
	{
		title: 'Workflow',
		icon: { icon: 'tabler-file' },
		open: true,
		children: [
			{
				title: 'Wash Bay',
				to: { name: 'second-page' },
				icon: { icon: 'tabler-file' },
			},
			{
				title: 'Completed',
				to: { name: 'second-page' },
				icon: { icon: 'tabler-file' },
			},
		],
	},
	{
		title: 'Cashup',
		to: { name: 'login' },
		icon: { icon: 'tabler-zoom-money' },
	},
];
